import { nothing } from 'lit-html';
import { html, property } from 'lit-element';
import { ifNotNull } from '../../utils/directives';
import { KatLitElement, register } from '../../shared/base';

import baseStyles from '../../shared/base/base.lit.scss';
import styles from './link.lit.scss';

/**
 * @component {kat-link} KatalLink Links are used primarily as navigational elements.
 * @guideline Do Use links to navigate to a new URL by specifying the href attribute.
 * @guideline Dont Don't use links to trigger JavaScript functions using the "onclick" handler. Some browsers do not support proper keyboard accessibility in this case.
 * @slot default Elements without a named slot attribute appear inside the link.
 * @example Basic {"label": "Amazon", "href":"https://www.amazon.com", "variant":"link"}
 * @example Slots {"href": "https://www.amazon.com", "target": "_blank", "content": "<span>Slotted link content 🔗</span>"}
 * @example AsButton {"label": "Amazon", "href":"https://www.amazon.com", "variant":"button"}
 * @status Production
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-link')
export class KatLink extends KatLitElement {
  /** The text to display for the link */
  @property()
  label?: string;

  /** The location to navigate to */
  @property()
  href?: string;

  /**
   * Specifes how to open the link
   * @enum {value} _self Opens in the current browsing context (default)
   * @enum {value} _blank Opens in a new window
   * @enum {value} _parent Opens in the parent browsing context of the current one. If no parent, behaves as _self.
   * @enum {value} _top Opens in the topmost browsing context (the "highest" context that’s an ancestor of the current one). If no ancestors, behaves as _self.
   */
  @property()
  target?: string;

  /**
   * Sets the visual variant of the link
   * @enum {value} link Displays as a traditional link - Default
   * @enum {value} button Displays the link as a button link
   */
  @property()
  variant?: string;

  /** If the attribute has a value, it is used as the pre-filled file name in the Save prompt (the user can still change the file name if they want). */
  @property()
  download?: string;

  /**
   * The URL that the hyperlink points to.
   * Links are not restricted to HTTP-based URLs — they can use any URL scheme supported by browsers.
   */
  @property()
  hreflang?: string;

  /**
   * A space-separated list of URLs.
   * When the link is followed, the browser will send POST requests with the body PING to the URLs.
   */
  @property()
  ping?: string;

  /** How much of the referrer to send when following the link. */
  @property()
  referrerPolicy?: string;

  /** The relationship of the linked URL as space-separated link types. */
  @property()
  rel?: string;

  /** Hints at the linked URL’s format with a MIME type. No built-in functionality. */
  @property()
  type?: string;

  static get styles() {
    return [baseStyles, styles];
  }

  createRenderRoot() {
    return this.attachShadow({ mode: 'open', delegatesFocus: true });
  }

  render() {
    return html`
      <a
        part="link"
        class="link"
        href=${ifNotNull(this.href)}
        target=${ifNotNull(this.target)}
        download=${ifNotNull(this.download)}
        hreflang=${ifNotNull(this.hreflang)}
        ping=${ifNotNull(this.ping)}
        referrerpolicy=${ifNotNull(this.referrerPolicy)}
        rel=${ifNotNull(this.rel)}
        type=${ifNotNull(this.type)}
      >
        <slot>
          ${this.label
            ? html`<span class="link__inner">${this.label}</span>`
            : nothing}
        </slot>
      </a>
    `;
  }
}
