import { html, property } from 'lit-element';
import { nothing } from 'lit-html';
import { ifNotNull } from '../../utils/directives';
import { formInputMap } from '../../utils/form-input-map';
import { register, nextUniqueId, KatLitElement } from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import formItemStyles from '../../shared/base/form-item.base.lit.scss';
import styles from './textarea.lit.scss';

/**
 * @component {kat-textarea} KatalTextarea A text area allows users to input a large amount of free-form text.
 * @status Production
 * @theme flo
 * @example Empty {"value": "", "placeholder": "", "disabled": "", "label": "", "constraint-label": "", "constraint-emphasis": "", "state": "", "state-emphasis": "", "state-label": ""}
 * @example Placeholder {"value": "", "placeholder": "Place example text here", "disabled": "", "label": "", "constraint-label": "", "constraint-emphasis": "", "state": "", "state-emphasis": "", "state-label": ""}
 * @example Pre-filled {"value": "Lorem ipsum...", "placeholder": "", "disabled": "", "label": "", "constraint-label": "", "constraint-emphasis": "", "state": "", "state-emphasis": "", "state-label": ""}
 * @example Disabled {"value": "Lorem ipsum...", "placeholder": "", "disabled": "true", "label": "", "constraint-label": "", "constraint-emphasis": "", "state": "", "state-emphasis": "", "state-label": ""}
 * @example Complex {"value": "Way back when I was just a little bitty boy living in a box under the stairs of corner of the basement of the house half a block down the street from Jerrys Bait Shop; you know the place...", "disabled": "", "placeholder": "Way back when I was...", "label": "Life Story", "constraint-label": "Must be thorough and dramatic", "constraint-emphasis": "Note", "state": "error", "state-emphasis": "Warning", "state-label": "Stories about New Mexico are too long and not permitted"}
 * @example WithTooltip {"value": "", "placeholder": "Place example text here", "label": "Textarea with Tooltip",
 * "tooltip-text": "tooltip content!"}
 * @example Small {"value": "", "placeholder": "Place example text here", "label": "Small Textarea", "size": "small"}
 * @event blur This event fires whenever the textarea is un-selected.
 * @event input This event fires whenever the user types a single character.
 * @event change This event fires whenever the user commits their change.
 * @event focus This event fires whenever the textarea is selected.
 * @event keydown This event fires whenever the keyboard starts entering a character.
 * @event keyup This event fires whenever the keyboard is finished entering a character.
 * @event mouseup This event fires whenever the mouse button is released.
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-textarea')
export class KatTextarea extends KatLitElement {
  _textarea: HTMLDataElement;
  _value: string;

  /** The id on the inner textarea, only necessary if the user plans to wire up their own label element */
  @property({ attribute: 'unique-id' })
  uniqueId: string = this.uniqueId ? this.uniqueId : nextUniqueId();

  /** This is the text that will be displayed to the user */
  @property({ reflect: false })
  get value(): string {
    if (this._textarea) {
      return this._textarea.value;
    }
    return this._value;
  }

  set value(val: string) {
    const oldVal = this.value;
    this._value = val;
    if (this._textarea) {
      // keep this updated so that when lit checks if it needs to re-render, it
      // sees the new value
      this._textarea.value = val;
    }
    this.requestUpdate('value', oldVal);
  }

  /** The name that will be submitted in a form */
  @property()
  name?: string;

  /** The text that will be displayed to the user until the user enters text */
  @property()
  placeholder?: string;

  /** Label that describes what the input represents */
  @property()
  label?: string;

  /** Optional pretext for the constraint label.  Used to provide additional context to the constraint for the input */
  @property({ attribute: 'constraint-emphasis' })
  constraintEmphasis?: string;

  /** Provides users with more information about what they enter into the input */
  @property({ attribute: 'constraint-label' })
  constraintLabel?: string;

  /**
   * The size of the selected option.
   * @enum {value} large Large textarea - Default
   * @enum {value} small Small textarea
   */
  @property()
  size?: 'large' | 'small' = 'large';

  /**
   * Setting the state of the input changes its look to give the user more information about what they have entered.  This value must be set for State Labels to show up.
   * @enum {value} error Lets the user know there is a problem with the value they have entered in the input
   */
  @property()
  state?: string;

  /** Optional pretext for the state label.  Used to provide additional context to the state for the input */
  @property({ attribute: 'state-emphasis' })
  stateEmphasis?: string;

  /** Provides users with more information about why the input is in the state its in */
  @property({ attribute: 'state-label' })
  stateLabel?: string;

  /** Prevents the user from changing the value of an input and prevents events from being triggered */
  @property()
  disabled?: boolean;

  /** Prevents the user from submitting unless provided */
  @property()
  required?: boolean;

  /** The maximum number of characters allowed in the element */
  @property()
  maxLength?: number;

  /** The label of the textarea visible only to screenreaders. */
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel?: string;

  /**
   * The text to be shown inside the tooltip placed next to the label text. The tooltip will only appear if this is
   * set.
   */
  @property({ attribute: 'tooltip-text' })
  tooltipText?: string;

  /** The position of the tooltip. Defaults to "top". */
  @property({ attribute: 'tooltip-position' })
  tooltipPosition?: string;

  /** The icon that triggers the tooltip next to the label. Defaults to "help_outline". */
  @property({ attribute: 'tooltip-trigger-icon' })
  tooltipTriggerIcon?: string;

  /**
   * Controls whether element may be checked for spelling errors. Defaults to false.
   * See https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/spellcheck#security_and_privacy_concerns before enabling.
   */
  @property({ attribute: 'kat-spellcheck' })
  katSpellcheck = false;

  static get styles() {
    return [baseStyles, formItemStyles, styles];
  }

  firstUpdated() {
    super.firstUpdated();
    this._textarea = this._shadow('textarea') as HTMLDataElement;
  }

  @formInputMap([
    {
      tag: 'textarea',
      name: (component: KatTextarea) => component.name,
      isNeeded: (component: KatTextarea) => component.isFormInputNeeded(),
      setup: (component: KatTextarea, input: HTMLTextAreaElement) =>
        component.setupFormInput(input),
    },
  ])
  updated(changedProps) {
    super.updated(changedProps);
  }

  isFormInputNeeded() {
    return !(this.disabled || !this.name);
  }

  setupFormInput(input: HTMLTextAreaElement) {
    input.value = this.value;
    this.required && input.setAttribute('required', 'required');
  }

  // change is the only native event fired by textarea that isn't composed by
  // default, so we have to re-emit it
  _handleChange(e) {
    const event = new Event('change');
    this.dispatchEvent(event);

    // Make sure we update LightDOM
    const changes = new Map();
    changes.set('value', e.target.value);
    this.updated(changes);
  }

  focus() {
    this._textarea.focus();
  }

  render() {
    return html`
      ${this.label
        ? html`
            <kat-label
              class="kat-label form-label"
              part="textarea-label"
              for="${this.uniqueId}"
              text="${ifNotNull(this.label)}"
              .tooltipText=${ifNotNull(this.tooltipText)}
              .tooltipPosition=${ifNotNull(this.tooltipPosition)}
              .tooltipTriggerIcon=${ifNotNull(this.tooltipTriggerIcon)}
            ></kat-label>
          `
        : nothing}
      <textarea
        id="${this.uniqueId}"
        part="textarea"
        aria-label=${ifNotNull(this.katAriaLabel)}
        class="${ifNotNull(this.state)}"
        placeholder="${ifNotNull(this.placeholder)}"
        name="${ifNotNull(this.name)}"
        @change=${this._handleChange}
        .value=${this.value || ''}
        ?disabled="${this.disabled}"
        ?required="${this.required}"
        maxlength="${ifNotNull(this.maxLength)}"
        spellcheck="${this.katSpellcheck}"
      ></textarea>
      <div class="metadata">
        ${this.constraintLabel || this.constraintEmphasis
          ? html`
              <kat-label
                class="kat-label constraint-label"
                for="${this.uniqueId}"
                variant="constraint"
                state="default"
                emphasis="${ifNotNull(this.constraintEmphasis)}"
                text="${ifNotNull(this.constraintLabel)}"
              ></kat-label>
            `
          : nothing}
        ${this.stateLabel || this.stateEmphasis
          ? html`
              <kat-label
                class="kat-label state-label"
                for="${this.uniqueId}"
                variant="constraint"
                state="${ifNotNull(this.state)}"
                emphasis="${ifNotNull(this.stateEmphasis)}"
                text="${ifNotNull(this.stateLabel)}"
              ></kat-label>
            `
          : nothing}
      </div>
    `;
  }
}
