import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiContext } from 'src/context/apiContext';
import { usePromiseTracker } from 'react-promise-tracker';
import { KatSpinner, KatLink } from '@amzn/katal-react';
import { ContentLayout } from 'src/components/ContentLayout/ContentLayout';

export function Home() {
  const { promiseInProgress } = usePromiseTracker();
  const navigate = useNavigate();
  const { state } = useContext(ApiContext);
  useEffect(() => {
    if (state.search.curAccountNo) {
      navigate(`/accounts/${state.search.curAccountNo}`);
    }
  }, []);

  return promiseInProgress ? (
    <KatSpinner size="large" />
  ) : (
    <ContentLayout
      heading={<h1>Welcome to MLPigeon</h1>}
      description="Please select an account or click the link below to onboard a new one."
    >
      <div>
        <Link to="/accountSetup">
          <KatLink>Onboard a New Account</KatLink>
        </Link>
      </div>
    </ContentLayout>
  );
}
