import React from 'react';
import { KatTab, KatTabs } from '@amzn/katal-react';
import { DataTable } from 'src/components/DataTable/DataTable';

interface Props {
  metricsData: ModelMetric[] | null;
  metricsLoading: boolean;
  tabToColumns: any;
}

function getValues(buckets: any): string[] {
  return buckets.map((b: any) => b['value'] + ' : ' + b['count'] + ', ');
}

export const CategoricalStatisticsTables = ({
  metricsData,
  metricsLoading,
  tabToColumns,
}: Props) => {
  const dataQualityMetricsData = metricsData
    ? metricsData.map((metric) => {
        const data: DataQualityMetricsDataString = {
          jobName: `${metric.jobType}:${metric.jobId}`,
          createdDate: metric.createdDate,
          missingPercentage: {},
          valueCount: {},
        };
        const metricsJson = metric?.metrics ? JSON.parse(metric?.metrics) : [];
        if (metricsJson?.features) {
          let header: string | null;
          let index = 2;
          metricsJson.features.forEach((feature: Feature) => {
            if (feature.string_statistics) {
              if (feature.string_statistics.distribution) {
                header = tabToColumns.valueCount[index]?.Header;
                if (header) {
                  data.valueCount[header] = getValues(
                    feature.string_statistics.distribution.categorical.buckets,
                  ).map((item: any, i: number) => <div key={i}>{item}</div>);
                }
              }
              const missingNum = feature.string_statistics.common.num_missing;
              const presentNum = feature.string_statistics.common.num_present;

              const missingPercentageHeader =
                tabToColumns.missingPercentage[index]?.Header;
              if (missingPercentageHeader) {
                data.missingPercentage[missingPercentageHeader] =
                  ((missingNum * 100) / (presentNum + missingNum)).toFixed(2) +
                  '%';
              }
              index++;
            }
          });
        }
        return data;
      })
    : [];

  const dataTableOptions = {
    loadingText: 'Loading metrics data...',
    defaultSort: {
      id: 'created-date',
      desc: true,
    },
  };

  return (
    <KatTabs selected-tab-id="6">
      <KatTab tabId="6" label="Percentage of missing values">
        <>
          <DataTable
            loading={metricsLoading}
            options={dataTableOptions}
            data={dataQualityMetricsData}
            columns={tabToColumns.missingPercentage}
            heading={<></>}
          />
        </>
      </KatTab>
      <KatTab tabId="7" label="Values/Counts">
        <>
          <DataTable
            loading={metricsLoading}
            options={dataTableOptions}
            data={dataQualityMetricsData}
            columns={tabToColumns.valueCount}
            heading={<></>}
          />
        </>
      </KatTab>
    </KatTabs>
  );
};
