import React from 'react';
import { OperatorFlowLink } from 'src/components/OperatorFlowLink/OperatorFlowLink';

export const OperatorFlowDetails = ({
  applicationData,
  operatorFlowData,
}: {
  applicationData: {
    applicationName?: string;
    modelVersionName?: string;
    modelArtifactName?: string;
  };
  operatorFlowData?: OperatorFlowJob | null;
}) => (
  <div className="operator-flow-details">
    <table className="operator-flow-details__list">
      <tbody>
        <tr>
          <td className="operator-flow-details__list-label">
            Application Name
          </td>
          <td>{applicationData.applicationName}</td>
        </tr>
        <tr>
          <td className="operator-flow-details__list-label">
            Model Version Name
          </td>
          <td>{applicationData.modelVersionName}</td>
        </tr>
        <tr>
          <td className="operator-flow-details__list-label">
            Model Artifact Name
          </td>
          <td>{applicationData.modelArtifactName}</td>
        </tr>
        <tr>
          <td className="operator-flow-details__list-label">Region</td>
          <td>{operatorFlowData?.region}</td>
        </tr>
        {operatorFlowData?.rolledBackOperatorFlowJobId && (
          <tr>
            <td className="operator-flow-details__list-label">
              Origin Operator Flow Job
            </td>
            <td>
              <OperatorFlowLink
                operatorFlowJobId={
                  operatorFlowData?.rolledBackOperatorFlowJobId
                }
              />
            </td>
          </tr>
        )}
        {operatorFlowData?.redeployedOperatorFlowJobIds &&
          operatorFlowData.redeployedOperatorFlowJobIds.length > 0 && (
            <tr>
              <td className="operator-flow-details__list-label">
                Redeployment Operator Flow Jobs
              </td>
              <td>
                {operatorFlowData?.redeployedOperatorFlowJobIds.map((id) => (
                  <div key={id}>
                    <OperatorFlowLink operatorFlowJobId={id} />
                  </div>
                ))}
              </td>
            </tr>
          )}
      </tbody>
    </table>
  </div>
);
