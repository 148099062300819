import React from 'react';
import { KatTab, KatTabs } from '@amzn/katal-react';
import { DataTable } from 'src/components/DataTable/DataTable';

interface Props {
  metricsData: ModelMetric[] | null;
  metricsLoading: boolean;
  tabToColumns: any;
}

export const NumericalStatisticsTables = ({
  metricsData,
  metricsLoading,
  tabToColumns,
}: Props) => {
  const dataQualityMetricsData = metricsData
    ? metricsData.map((metric) => {
        const data: DataQualityMetricsData = {
          jobName: `${metric.jobType}:${metric.jobId}`,
          createdDate: metric.createdDate,
          min: {},
          max: {},
          mean: {},
          sum: {},
          stdDev: {},
          missingPercentage: {},
        };

        const metricsJson = metric?.metrics ? JSON.parse(metric?.metrics) : [];
        metricsJson?.features?.forEach((feature: Feature) => {
          if (feature.numerical_statistics) {
            data.min[feature.name] = feature.numerical_statistics.min;
            data.max[feature.name] = feature.numerical_statistics.max;
            data.mean[feature.name] = feature.numerical_statistics.mean;
            data.sum[feature.name] = feature.numerical_statistics.sum;
            data.stdDev[feature.name] = feature.numerical_statistics.std_dev;
            const missingNum = feature.numerical_statistics.common.num_missing;
            const presentNum = feature.numerical_statistics.common.num_present;
            data.missingPercentage[feature.name] =
              ((missingNum * 100) / (presentNum + missingNum)).toFixed(2) + '%';
          }
        });
        return data;
      })
    : [];

  const dataTableOptions = {
    loadingText: 'Loading metrics data...',
    defaultSort: {
      id: 'created-date',
      desc: true,
    },
  };

  return (
    <KatTabs selected-tab-id="0">
      <KatTab tabId="0" label="Min">
        <>
          <DataTable
            loading={metricsLoading}
            options={dataTableOptions}
            data={dataQualityMetricsData}
            columns={tabToColumns.min}
            heading={<></>}
          />
        </>
      </KatTab>
      <KatTab tabId="1" label="Max">
        <>
          <DataTable
            loading={metricsLoading}
            options={dataTableOptions}
            data={dataQualityMetricsData}
            columns={tabToColumns.max}
            heading={<></>}
          />
        </>
      </KatTab>
      <KatTab tabId="2" label="Mean">
        <>
          <DataTable
            loading={metricsLoading}
            options={dataTableOptions}
            data={dataQualityMetricsData}
            columns={tabToColumns.mean}
            heading={<></>}
          />
        </>
      </KatTab>
      <KatTab tabId="3" label="Sum">
        <>
          <DataTable
            loading={metricsLoading}
            options={dataTableOptions}
            data={dataQualityMetricsData}
            columns={tabToColumns.sum}
            heading={<></>}
          />
        </>
      </KatTab>
      <KatTab tabId="4" label="Standard deviation">
        <>
          <DataTable
            loading={metricsLoading}
            options={dataTableOptions}
            data={dataQualityMetricsData}
            columns={tabToColumns.stdDev}
            heading={<></>}
          />
        </>
      </KatTab>
      <KatTab tabId="5" label="Percentage of missing values">
        <>
          <DataTable
            loading={metricsLoading}
            options={dataTableOptions}
            data={dataQualityMetricsData}
            columns={tabToColumns.missingPercentage}
            heading={<></>}
          />
        </>
      </KatTab>
    </KatTabs>
  );
};
